.uploaded-image-wrapper {
    display: inline-block;
    margin-bottom: 20px;
    
    .user-profile {
        .avatar {
            width: 30px;
            height: 30px;
            flex: 0 0 30px;

            span {
                font-size: 14px;
                line-height: 30px;

                i {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }
}

.screenshot-preview {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: var(--gray-4);
}