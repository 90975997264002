.upload-image-box {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    .file-base64-label-wrapper {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 0;
        border: 0px dashed var(--gray-1);
        cursor: pointer;
        margin-right: 20px;

        &:hover {
            background-color: var(--gray-5) !important;
        }
    }

    .file-base64-label-wrapper .file-image-preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--gray-5);
    }

    .file-upload-text {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
    }
}

.upload-logo-wrapper {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    background-color: var(--gray-3);

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover !important;
    }

    .reset-image {
        top: 10px !important;
        right: 30px !important;
    }
}