.clash-royale-tab {
    .card-holder {
        position: relative;
        margin-bottom: 20px;
        
        img {
            width: 100%;
        }

        sup.card-level {
            position: absolute;
            bottom: -5px;
            top: auto;
            width: 100%;
            text-align: center;
            color: white;

        }


    }
}