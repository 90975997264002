@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');


.App.play-play ~ div#skpModalOverlay {
    display: none;
}

.App.play-play {
    font-family: 'Outfit', sans-serif;
    background-color: transparent !important;

    .gray-bg {
        color: rgba(107, 114, 128, 1);
        background-color: rgba(243, 244, 246, 1);

        &-hover:hover {
            color:rgb(31, 41, 55);
            background-color: rgb(232, 236, 242);
            cursor: pointer;
        }
    }

    .skp-text-primary {
        color: #6b7280;
    }

    .app-loading,
    .skp-cookie-banner {
        display: none !important;
    }

    .navbar {
        display: none !important;
    }

    .left-nav {
        display: none !important;
    }

    .main-section {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        width: 100vw !important;
        height: 100vh !important;
        z-index: 9999 !important;
        background-image: none !important;
        background-color: transparent !important;
        overflow-y: hidden;
    }

    .play-tabs-wrapper {
        font-family: 'Outfit', sans-serif;
        color: rgba(107, 114, 128, 1);
        display: flex;

        .play-tab {
            flex: 1;
            height: 3rem;
            border-bottom: 1px solid;
            border-color: rgba(107, 114, 128, 1);
            font-size: .875rem;
            line-height: 1.25rem;

            &.active {
                // background-color: fuchsia;
                border-bottom: 2px solid;
                border-color: rgba(99, 102, 241, 1) !important;
                color: rgba(99, 102, 241, 1);
            }

            &:hover {
                cursor: pointer;
                border-bottom: 2px solid;
                border-color: rgba(55, 68, 81, 1);
            }
        }

        select {
            width: 100%;
            line-height: 24px;
            border-radius: 0.375rem;
            padding: 8px 2.5rem 8px 12px;
            color-adjust: exact;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
            background-position: right 0.5rem center;
            background-repeat: no-repeat;
            background-size: 1.5em 1.5em;
            -webkit-print-color-adjust: exact;
            background-color: white;
            border-color: rgba(209, 213, 219, 1);
            color: rgb(31, 41, 55);
            -webkit-appearance: none;
            -moz-appearance: none;

            &:focus {
                outline: none !important;
                border-color: rgb(99, 102, 241);
                box-shadow: 0 0 0px rgb(99, 102, 241);
            }
        }
    }

    .small-player-wrapper {
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 50%;
        overflow: hidden;
        margin: 10px;

        border: 1px solid;
        border-color: rgba(245, 158, 11, 1);
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .player-wrapper {
        width: 5rem;
        height: 5rem;
        margin: 0 auto;
        border-radius: 50%;
        overflow: hidden;

        &:hover {
            border: 2px solid;
            border-color: rgba(245, 158, 11, 1);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .leaderboard-user {
        .player-wrapper {
            width: 2rem;
            height: 2rem;
            margin: 0 5px;
            border-radius: 50%;
            overflow: hidden;
        }
    }

    .leaderboard-row {
        display: flex;
        margin: 0;
        padding: 10px 0 10px 0;
        border-bottom: 1px solid rgba(107, 114, 128, .3);

        .player-wrapper {
            margin: 0;
            flex: 0 0 2rem;
            min-width: 0;
            height: 2rem;
        }
    }

    .play-table-view {
        padding: 0;
        --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .bordered-list {
        background-color: white;
        border-color: transparent;
        color: rgba(107, 114, 128, 1);

        &:nth-child(even) {
            background-color: rgba(243, 244, 246, 1);
        }
    }

    .bordered-list dd:hover {
        background-color: white;
        cursor: auto;
    }

    .bordered-list dd:hover:not(.hideHover) {
        background-color: #f3f3f3;
        cursor: pointer;
    }

    .column-gray {
        color: rgba(107, 114, 128, 1);
        background-color: rgba(243, 244, 246, 1);
        margin: 0;
    }

    .info-hover-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 110px;
        left: 0;
        z-index: 9;
        font-size: 1rem;
        padding: 0 50px;
        overflow-y: scroll;

        &.top-0 {
            top: 60px;
            height: calc(100% - 60px);
        }

        .close-btn {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 24px;
        }
    }

    .infinite-scroll-component dd {
        margin: 0;
        padding: 10px;
    }

    .brackets-tab {
        overflow: scroll;
        height: 100%;
    }

    .skp-brackets-seed-details {
        .skp-link-primary {
            color: var(--quaternary) !important;
        }
    }

    .skp-brackets-seed-item {

        &-team-home,
        &-team-away {
            background-color: #f1f0f0;
            border-bottom: 1px solid #1b1c23;

        }

        .skp-brackets-seed-item-team > :nth-child(2) {
            border-top: 1px solid #1b1c23;
        }

        .skp-brackets-seed-item-team > :nth-child(1) {
            border-bottom: 0px solid #1b1c23;
        }

        .player-score {
            color: #1b1c23;
        }
        
        .player-wrapper {
            position: relative;
            width: 30px;
            height: 30px;
            flex: 0 0 30px;
            background-color: yellow;
    
            ~ .text-label {
                padding: 0.4rem;
                width: 120px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                justify-content: left;
                cursor: pointer;
                color: #1b1c23;
            }
        }
    }

    .skp-brackets-seed-item {
        background-color: white;
        box-shadow: none;
    }

    .skp-pp-test {
        background-color: white !important;
        color: #1b1c23;
    }

}

.play-skp-card {
    margin: 20px;
}

.play-skp-bg {
    background-color: #f1f0f0;
    padding: 15px;
    border-radius: 10px;
}

.play-game-card {
    border: 1px solid #1b1c23;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;

    &:hover {
        border: 2px solid;
        border-color: rgba(245, 158, 11, 1);
        cursor: pointer;
    }
}

.play-skp-card {
    margin-bottom: 15px;
}

