.main-container {
    height: 100vh !important
}

.day-scrollbar {
    width: 100% !important;
    height: calc(100vh - 100px) !important;
}

.settings-tab {
    position: fixed;
    z-index: 9;
    right: 15px;
    bottom: 20px;
    width: 300px;

    &-dropdown {
        position: absolute;
        top: -180px;
        right: 0px;
        width: 200px;
    }

}


.small-link {
    color: var(--primary-blue);
    cursor: pointer;
    text-transform: uppercase;

    &:hover,
    &.active {
        color: var(--primary-blue);
        font-weight: 900;

    }
}

.large-link {
    color: white;
    cursor: pointer;
    text-transform: uppercase;

    &:hover,
    &.active {
        font-weight: 900;

    }
}

.milanocomics-event-wrapper {
    position: fixed;
    width: 100%;
    height: calc(100vh - 60px);
    top: 60px;
    left: 0;
    z-index: 0;
    background-image: url("https://storage.googleapis.com/skillpro-bucket/FHD-SkillPro-Landing.jpg") !important;
    background-size: cover !important;
    //     filter: blur(1px);
    //   -webkit-filter: blur(1px);
}

.logo-placeholder {
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    &.main-logo {
        width: 100%;
    }

    img {
        width: 100%;
    }
}

.fullscreen-enabled {
    .wearena-event-wrapper {
        height: 100vh !important;
        top: 0px !important;

    }

    .day-scrollbar {
        height: 100vh !important;
    }

    .main-container {
        height: 100% !important;

        > div {
            overflow-y: hidden !important;
        }
    }
}

@media (max-width: 480px) {
    .main-logo {
        display: inline-block;
        width: 33%;
    }

    .day-scrollbar {
        height: calc(100vh - 300px) !important;
    }

    .main-container {
        height: calc(100vh - 150px) !important;
    }

    .settings-tab {
        display: none;
    }

    .large-link {
        font-size: 16px;
        
    }
}