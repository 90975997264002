:root {
  --effect: hover 1s linear infinite;
}

.animated-loader {
  text-align: center;

  p {
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    font-size: 4em;
    font-family: arial;
    font-weight: 600;
    transform: scale(0.5);
    color: white;
    -webkit-text-stroke: 2px gray;
  }

  p:nth-child(1) {
    animation: var(--effect);
  }

  p:nth-child(2) {
    animation: var(--effect) 0.125s;
  }

  p:nth-child(3) {
    animation: var(--effect) 0.25s;
  }

  p:nth-child(4) {
    animation: var(--effect) 0.375s;
  }

  p:nth-child(5) {
    animation: var(--effect) 0.5s;
  }

  p:nth-child(6) {
    animation: var(--effect) 0.675s;
  }

  p:nth-child(7) {
    animation: var(--effect) 0.75s;
  }

  @keyframes hover {
    0% {
      transform: scale(0.5);
      color: #121212;
      -webkit-text-stroke: 2px gray;
    }

    20% {
      transform: scale(1);
      color: pink;
      -webkit-text-stroke: 3px red;
      filter: drop-shadow(0 0 1px black) drop-shadow(0 0 1px black)
        drop-shadow(0 0 3px red) drop-shadow(0 0 5px red) hue-rotate(10turn);
    }

    50% {
      transform: scale(0.5);
      color: #121212;
      -webkit-text-stroke: 2px gray;
    }
  }
}
