.chat-tab {
    height: calc(100vh - 460px);
    padding-bottom: 10px !important;
}

.chat-tab-emoji-container {
    z-index: 10;
    position: fixed;
    bottom: 90px;
    right: 300px;
}

.chat-tab-msg-reply-balloon-replyed-content {
    padding: 12px;
    border-radius: 6px;
    background-color: var(--secondary) !important;
    margin-left: 10px;
    margin-top: 10px;
    border-left: 0.5rem solid;
    border-color: var(--gray-1);
    width: 100%;    
}

.load-more-messages {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0; /* Aggiunge un po' di spazio sopra e sotto il pulsante */
    width: 100%; /* Assicura che il pulsante sia centrato orizzontalmente */
}
