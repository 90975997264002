// Players Tab
.players-tab,
.tab-with-table {
    dd {
        padding: 10px;
        border-bottom: 1px solid var(--gray-3);
        margin: 0;

        a {
            text-decoration: none;

            // &:hover {
            //     span {
            //         color: var(--primary) !important;
            //     }

            // }
        }

        .admin-btn-wrapper {
            display: none;
        }

        &:hover {
            .admin-btn-wrapper {
                display: block;
            }
        }
    }
}

.brackets-tab {
    width: 100%;
    overflow-x: scroll;
}
