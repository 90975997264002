.registration-form {
    display: inline-block;
    width: 100%;
    padding: 15px 30px;
    margin-top: -20px;

    form {
        button[type="submit"] {
            width: 100%;
            margin-top: 15px;

            // TODO: Move inside global style
            span.loading {
                display: inline-block;

                &:after {
                    border: .1rem solid white;
                    border-right-color: transparent;
                    border-top-color: transparent;
                }
            }
        }
    }

    .avatar {
        font-size: 100px;
        line-height: 115px;
        width: 6rem;
        height: 6rem;

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }

}