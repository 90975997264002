.skp-chat-conversation-page {
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}

.skp-chat-conversation-header {
    height: 70px;
    background: var(--gray-2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .user-profile {
        margin-left: 10px;
    }
}

.skp-chat-conversation-header-buttons {
    display: flex;
    flex-direction: row;
    text-align: center;
    padding-right: 30px;
    gap:20px
}

.skp-chat-header-buttons-space-divider {
    display: inline-block;
    width: 2px;
    height: 80%;
    margin: 7px -7px;
    background-color: var(--gray-1);
}

.skp-chat-header-button-icon {
    font-size: 24px !important;
}

.skp-chat-conversation-body {
    flex: auto;
}

.skp-chat-conversation-footer {
    height: 70px;
    background: var(--gray-2);
}

.skp-chat-reply-conversation-footer {
    height: 85px;
    background: var(--gray-2);
}

.skp-msg-reply-balloon-replyed-content {
    padding: 12px;
    border-radius: 6px;
    background-color: var(--secondary) !important;
    margin-left: 53px;
    margin-top: 10px;
    border-left: 0.5rem solid;
    border-color: var(--gray-1);
    width: 100%;    
}

.skp-delete-reply-button {
    cursor: pointer;
     margin: 22px 0px 0px -4px; 
    padding: 10px;
}

.skp-delete-reply-icon {
    padding-right: 10px;
    //margin-left: -10px;
    font-size: 24px;
}

.skp-msg-reply-username-footer {
    color: var(--primary-blue);
    padding-bottom: 8px;
}

.skp-reply-text-footer {
    height: 18px;
    overflow: hidden;
}

.skp-reply-image-root {
    display: flex;
    justify-content: space-between;

    .skp-reply-image {
        width: 32px;
        height: 32px;
        position: relative;
        bottom: 6px;
        right: 0px;
        border-radius: 6px;
    }
}

.skp-reply-image {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 62px;
    bottom: 82px;
    border-radius: 6px;
}

.skp-chat-message-form {
    display: flex;
    flex-direction: row;
    text-align: center;
}

.skp-chat-message-form-control {
    width: 100%;
    margin: 10px 10px;
}

.skp-chat-message-form-button {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;    
    margin: 10px 10px 0px 0px;
    font-size: 20px;
}

.emoji-container {
    z-index: 10;
    position: fixed;
    bottom: 71px;
    right: 50px;
}

.skp-emoji-picker-button {
    cursor: pointer;
    margin: 18px 14px 0px -4px;
}

.skp-image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;

    .file-base64-label-wrapper {
        margin-right: 0px;
        margin-bottom: 20px;
    }
}

.skp-add-element-button {
    cursor: pointer;
    margin: 13px 0px 0px 10px;
    font-size: 30px;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
}

.skp-message-icon {
    position: relative;
    bottom: 6px;
    font-size: 30px !important;
}

.skp-new-chat-icon {
    position: relative;
    bottom: 9px;
    font-size: 30px !important;
}

.skp-header-inline-menu-icon {
    display: flex;
    flex-direction: row;
    text-align: center;
}

.szh-menu__arrow--dir-bottom {
    top: -0.75rem !important;
}