@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&family=Nova+Square&display=swap");

@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke * -1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i * 1px $j * 1px 0 $color, comma);
    }
  }
  @return $shadow;
}

@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}

$border-radius: 30px;
$red: #ff0000;

.nintendo-online-event-wrapper {
  background-image: url("https://storage.googleapis.com/skillpro-bucket/skp-website-images/bg_2.jpg") !important;
  background-size: cover !important;
}

.nintendo-online-event {
  position: relative;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 9;

  .skp-text-primary {
    color: white !important;
    @include stroke(1, black);
  }

  &-content {
    position: relative;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    z-index: 9;

    .main-column {
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 0;
      border: 5px solid $red;
      margin-right: 1rem;
      border-radius: $border-radius $border-radius 0 0;
      overflow: hidden;

      &-heading {
        background-color: $red;
        font-family: "Nova Square", cursive;
        font-size: 30px;
        text-align: center;
        margin-bottom: -40px;
        margin-top: -1px;
        padding: 5px;
      }

      dl {
        dd.row-lobby {
          font-family: "Nova Square", cursive;
          font-size: 20px;
          padding-top: 40px;
        }
        dd.row-title {
          display: none;
        }
      }

      .sub-columns {
        padding: 1rem;
        .column:nth-child(odd) {
          width: 60%;

          
        }

        .column:nth-child(even) {
          width: 40%;
          text-align: center;
        }
      }
    }
  }

  &-side {
    position: relative;
    width: 300px;
    height: 100vh;
    background-image: url("https://storage.googleapis.com/skillpro-bucket/Banda_LATERALE%20SX.png");
    background-size: cover;
    background-repeat: no-repeat;

    .side-content {
      position: absolute;
      top: 0;
      background-image: url("https://storage.googleapis.com/skillpro-bucket/LOGO_NO-EFFECTS.png");
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: 50%;
      background-repeat: no-repeat;
    }
  }

  .mario-kart-logo {
    position: fixed;
    width: 480px;
    height: 226px;
    background-image: url(https://storage.googleapis.com/skillpro-bucket/LOGO_NO-EFFECTS.png);
    background-size: cover;
    bottom: 50px;
    right: 80px;
    z-index: 0;
  }

  .icon-placement {
    position: relative;

    &.star:after {
      top: -10px;
      left: 15px;
      content: "";
      position: absolute;
      width: 28px;
      height: 28px;
      background-image: url("https://storage.googleapis.com/skillpro-bucket/ICONA-Stella_PRIMO%20CLASSIFICATO.png");
    }

    &.flower:after {
      top: -5px;
      left: 15px;
      content: "";
      position: absolute;
      width: 28px;
      height: 28px;
      background-image: url("https://storage.googleapis.com/skillpro-bucket/ICONA-Fiore_2%20CLASSIFICATO.png");
    }

    &.mushroom:after {
      top: -5px;
      left: 20px;
      content: "";
      position: absolute;
      width: 28px;
      height: 28px;
      background-image: url("https://storage.googleapis.com/skillpro-bucket/ICONA-Fungo_3%20CLASSIFICATO.png");
    }
  }
}

@media (max-width: 480px) {
  .skp-bg-image {
    // background-size: contain !important;
  }

  .nintendo-online-event {

    &-content {
      width: 100%;
      padding-top: 10px;
    }

    &-side {
      top: 0px;
      position: absolute;
      z-index: 1;
      left: 0;
      height: 100px;
      width: 100%;
    }
  }
}
