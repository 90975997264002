  
  .news-loading-placeholder {
    height: 200px;
  }
  
  .news-loading-title {
    height: 20px;
    background-color: #eee;
    margin-bottom: 10px;
  }
  
  .news-loading-content {
    height: 100px;
    background-color: #eee;
  }

  .news-page-container {
    height: 100vh;  /* Altezza della finestra */
    overflow-y: auto;  /* Abilita lo scroll verticale */
  }
  
  .news-page-content {
    padding: 20px;  /* Spazio interno per separare il contenuto dai bordi */
  }
  
  .news-full-image {
    width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
  }
  
  .news-full-content {
    text-align: left;
    margin-top: 20px;
  }

  .back-button {
    display: inline-block;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  