.news-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    background-color: var(--gray-5);
  }
  
  .news-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .news-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .news-content .news-text {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to display */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  
    /* Standard property (currently not widely supported, but future-proof) */
    line-clamp: 2; /* Number of lines to display */
  }

  .news-content {
    padding: 16px;
  }
  
  .news-title {
    margin: 0 0 10px;
  }
  
  .news-link {
    display: inline-block;
    color: #007bff;
    text-decoration: none;
  }
  
  .news-link:hover {
    text-decoration: underline;
  }

  .news-align-date {
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 30px;
  }

  .edit-delete-icons {
    display: flex;
    gap: 10px;
    top: 10px;
    right: 10px;
  }
  
  .edit-icon {
    cursor: pointer;
  }
  
  .delete-icon {
    cursor: pointer;
    color: red; /* Forza il colore rosso se non già definito in `skp-text-danger` */
  }
  