.skp-modal-tab-overlay {
    opacity: 0;
    position: fixed;
    background-color: rgba(227, 227, 227, 90%);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    overflow-y: scroll;
}

.skp-modal-tab {
    transform: translateY(-200px);
    max-width: 45rem;
    margin: 0 auto;
    padding-bottom: 3.2rem;
    padding-top: 3.2rem;
    padding-right: 0.6rem;
    padding-left: 0.6rem;

    &-small {
        max-width: 23rem;
    }

    &-medium {
        max-width: 29rem;
    }

    &-large {
        max-width: 35rem;
    }

    .skp-form-control .optionListContainer {
        background-color: var(--gray-3);
    }

    .skp-form-control  {
        textarea, input, select {
            background-color: white !important;
            color: black;
        }
        
    }
}

.skp-modal-tab-content {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 1.6em;
    background-color: #fefefe;
    padding: 20px 10px;
    overflow: hidden;

    .skp-text-primary {
        color: #1b1c23
    }

    .modal-submit-internal {

        button[type="submit"] {
            min-width: 80px;
            float: right;

            // TODO: Move inside global style
            span.loading {
                display: inline-block;

                &:after {
                    border: .1rem solid white;
                    border-right-color: transparent;
                    border-top-color: transparent;
                }
            }
        }
    }
}

.skp-modal-tab-header {
    flex: 1;
    text-align: center;

    h4 {
        text-transform: capitalize;
    }
}

.skp-modal-tab-body {
    flex: 1;
}

.skp-modal-tab-footer {
    flex: 1;
    text-align: right;

    button {
        margin-right: 15px;
    }
}

.skp-modal-tab-close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;

    i {
        font-size: 22px;
    }

    &:hover {
        color:  rgba(245, 158, 11, 1);
    }
}

.skp-modal-tab-overlay.enter-done {
    opacity: 1;
    transition: transform .3s ease;
}

.skp-modal-tab-overlay.enter-done .skp-modal-tab {
    transform: translateY(0);
    transition: transform .3s ease;
}

.skp-modal-tab {
    .bordered-list dd:hover {
        background-color: #f3f3f3;
        cursor: pointer;
    }

    .playnplay-match {

        .player-wrapper {
            position: relative;
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            background-color: #35383f;
            border-radius: 50%;
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                height: 100%;
                width: 100%;
                z-index: 1;
            }
        }

        .user-profile {
            .player-wrapper {
                ~ .text-label {
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
            &.row-reverse {
                .player-wrapper {
                    ~ .text-label {
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
                
            }
        }
    }
    
}
