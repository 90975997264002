.multiSelectContainer input {
    width: 100% !important;
    padding: 8px 10px !important;
    border-radius: 8px !important;
    border: 2px solid var(--gray-1) !important;
    background-color: var(--gray-4) !important;
    outline: none !important;
    color: var(--light) !important;
    font-size: 13px !important;
    -webkit-appearance: none !important;

    &:hover,
    &:focus {
        border-color: var(--primary) !important;
    }
}