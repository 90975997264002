.logo-link {
    display: inline-block;

    &-small {
        width: 6rem;

        &.active {
            width: 12rem;
        }
    }

    &-big {
        width: 20rem;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.sheet-list {
    dd {
        height: 50px;
        align-items: center;
        display: flex;
        justify-content: left;

        &:first-child {
            height: 20px;
        }
    }
}

.nav-buttons {
    a {
        background-color: rgba(255,255,255,0.5);
        display: inline-block;
        width: 100%;
        border-radius: 8px;
        color: black !important;
        padding: 8px;
        text-transform: uppercase;

        &.activeLink,
        &:hover {
            background-color: rgba(255,255,255,0.8);
            text-decoration: none;
        }
    }
}
