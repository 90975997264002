.skp-chat-card {
    background-color: var(--gray-3);
    height:70px; 
    border-radius: 5px;  
    margin: 0px 4px 0px -2px;
}

.skp-chat-card-user-avatar {
    margin-top: 10px;
}

.skp-chat-message-box {
    margin-top: 15px;
    margin-left: -15px;
}

.skp-chat-message-name {
    margin-right: -30px;
}

.skp-chat-message-text {
    margin-top: 5px;
    margin-right: -30px;
    max-width: 175px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.skp-chat-card-time {
    margin-top: 15px;
    text-align: right;
    margin-right: -13px;
}

.skp-chat-unread-circle {
    margin: 5px 0px 0px 56px;
    width: 20px;
    height: 20px;
    padding: 7px 0;
    color: var(--super-light);
    min-width: auto;
    background: transparent;
    border-radius: 50%;
    background-color: var(--primary);

    &:hover {
        opacity: 1;
    }

    i {
        font-size: 20px;
    }
}

.skp-chat-unread-circle {
    position: relative;
    sup {
        position: absolute;
        width:20px;
        height:20px;
        top:1px;
    }
}