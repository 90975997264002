.news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px 0;
  }
  
  @media (max-width: 768px) {
    .news-grid {
      grid-template-columns: 1fr;
    }
  }
  