.box-card {
    background-color: rgba(255,255,255,0.8);
    border-radius: 8px;
    padding: 10px;
    color: black;

    dd {
        margin: 0;
        padding: 15px 5px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.174);

        .skp-link-hover {
            color: black !important;
        }
    }
}