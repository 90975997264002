.fullscreen-bracket-container {
    background-color: black;
    height: 100vh;
}

.tab {
    display: flex;
    align-items: center;
  
    .push-right {
      margin-left: auto; // Spinge questo elemento a destra
      padding-right: 20px;
    }

    .pointer {
        cursor: pointer;
    }
  }

.color-0 {
  color: white !important;
  font-weight: 500; 
}

.color-1 {
  color: var(--primary) !important;
  font-weight: 500;
}

.color-2 {
  color: var(--accent) !important;
  font-weight: 500; 
}

.color-3 {
  color: #4ae29b !important;
  font-weight: 500; 
}