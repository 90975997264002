.skp-box-start {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 8px;
}

.skp-box-end {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 8px;
}

.skp-msg-start {
    justify-content: start;
    max-width: 60%;
}

.skg-msg-start-bg {
    background-color: var(--gray-3);
}

.skp-msg-end {
    justify-content: end;
    max-width: 60%;
}

.skg-msg-end-bg {
    background-color: var(--secondary);
}

.skp-msg-balloon {
    border-radius: 12px;
}

.skp-msg-text-default {
    font-size: 13px;
}

.skp-msg-balloon-content {
    padding: 8px;
    position: relative;
}

.skp-msg-balloon-replyed {
    padding: 3px;
}

.skp-msg-balloon-replyed-content {
    padding: 8px;
    border-radius: 6px;
    background-color: var(--gray-2) !important;
}

.skp-msg-text {
    padding-right: 45px;
}

.skp-msg-time {
    position: absolute;
    bottom: 8px;
    right: 12px;
}

.skp-msg-time-image {
    border-radius: 6px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgba(17, 18, 23, 0.2);
}
.skp-msg-username {
    color: var(--light);
}

.skp-msg-reply-padding
{
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.skp-msg-reply-username {
    color: var(--primary-dark);
}

.skp-img-start {
    justify-content: start;
    max-width: 60%;
}

.skp-img-end {
    justify-content: end;
    max-width: 60%;
}

.skp-timeline-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}

.skp-timeline-divider {
    border: 1px solid var(--gray);
    width: 45%;
}

.skp-info-message-box {
    padding: 20px;
    text-align: center;
}

.skp-message-inline-menu-icon {
    font-size: 24px;
}

.szh-menu {
    background-color: var(--gray-3) !important;
    font-size: 14px;
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 6px;
    min-width: 10rem;

    &__item {
        border-radius: 6px;
        padding: 0.375rem 0.625rem;
  
        &--hover {
          color: #fff;
          background-color: #59a2ff;
        }
      }
}

.szh-menu__arrow {
    background-color: var(--gray-3) !important;
}

.skp-menuitem-icon {
    padding-right: 10px;
    //margin-left: -10px;
    font-size: 20px;
}

.skp-reaction-submenu {
    display: flex;
    flex-direction: row;
}

.skg-reaction-element {
    position: absolute;
    bottom: -14px;
    font-size: 13px;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 15px;
    width: 40px;
    text-align: center;
    padding-top: 2px;
    border: 1px solid black;
}

.skp-reaction-element-outcoming {
    right: 15px;
    background-color: var(--gray-3);
    
}

.skp-reaction-element-incoming {
    background-color: var(--secondary);
}

.skp-msg-image {
    border-radius: 12px;
    max-width: 300px;
    padding: 5px;
}

.skp-emoji-menu-item {
    padding: 10px !important;
}

.skp-emoji-tooltip {
    display: flex;
    flex-direction: column;

    .user-profile {
        padding-right: 30px;
        .avatar {
            width: 30px;
            height: 30px;
            flex: 0 0 30px;

            span {
                font-size: 14px;
                line-height: 30px;

                i {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }

    .reaction {
        position: absolute;
        right: 15px;
        padding-top: 7px;
    }
}
