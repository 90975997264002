.user-settings-page {
    .avatar {
        width: 126px;
        height: 126px;
        margin-right: 16px;

        span {
            display: inline-block;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 126px;
            font-size: 63px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }

    button[type="submit"] {
        min-width: 80px;
        float: right;

        // TODO: Move inside global style
        span.loading {
            display: inline-block;

            &:after {
                border: .1rem solid white;
                border-right-color: transparent;
                border-top-color: transparent;
            }
        }
    }

    .username-heading {
        display: inline-block;
    }
}

