.profile-dropdown {
    position: relative;
    color: var(--gray);
    cursor: pointer;

    >span {
        padding: 10px 20px 14px 0;
        border-radius: 26px;
    }

    &:hover {
        >span {
            background-color: var(--gray-3);
        }

    }

    a {
        color: var(--light);

        &:hover,
        &:focus,
        &:active {
            color: var(--primary);
            text-decoration: none;
        }


    }

    ul {
        list-style: none;

        li {
            color: var(--light);
            cursor: pointer;
            padding: 2px 0;

            .icon-teams,
            .icon-tournaments {
                font-size: 14px;
            }
        }

        li.list-divider {
            height: 0;
            padding: 0;
            // border-bottom: 1px solid var(--gray-1);
        }
    }

    .avatar {
        width: 40px;
        height: 40px;
        margin-right: 16px;

        span {
            display: inline-block;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 40px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }

    .skp-dropdown {
        right: 0;
        top: 45px;
    }
}

.skp-dropdown {
    position: absolute;
    width: 200px;
    z-index: 9;
    background-color: var(--gray-3);
    border-radius: 8px;
}