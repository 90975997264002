@import "styles/fonts/skillpro-icons";
@import "styles/color_palette";

@import "styles/image-placeholder/images_base64";
@import "styles/ui-elements/ui_elements";
@import "styles/ui-components/ui-components";
@import "styles/responsive/layout.scss";
@import "styles/layout/layout";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&family=Nova+Square&display=swap");

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";



body,
.tooltip {
  font-family: "Montserrat", sans-serif;
}

.tooltip {
  
  &:after{
    font-family: "Montserrat", sans-serif !important;
    font-size: 14px !important;
  }
  
}

a,
a:hover {
  color: var(--primary);
}

.show-on-hover {
  button {
    visibility: hidden;
  }

  &:hover {
    button {
      visibility: visible;
    }
  }
}

.skp-notification-top-bar ~ .app-container {
  top: 30px;

  .left-nav {
    top: 86px;
  }

  .main-section {
    top: 86px;
  }
}

.App {
  a.active,
  a:active,
  a:focus,
  a:hover {
    outline: none;
    box-shadow: none;
  }
}

.skp-bg-image {
  background-image: $skp_bg_image;
  background-size: contain;
}

#teamHomeScore,
#teamAwayScore {
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
}

.winner {
  position: absolute;
}

.screenshots-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-base64-label-wrapper {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
  background-color: transparent;
  border-radius: 8px;
  border: 3px dashed var(--gray-1);
  cursor: pointer;
  margin-right: 20px;

  &.no-border {
    border: 3px solid var(--gray-1);
  }

  &:hover {
    background-color: var(--gray-4);
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-indent: -999px;
    cursor: pointer;
    z-index: 9;
  }

  .file-image-preview {
    position: absolute;
    top: 0;
    width: 145px;
    height: 145px;
    background-color: var(--gray-5);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .reset-image {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 10;
    }
  }
}

.logo-skillpro {
  display: inline-block;
  width: 144px;
  height: 30px;
  background-image: $skillpro_logo;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 5px 0 0 8px;
  cursor: pointer;
  transition: background-image 0.2s ease-in-out;

  &-xl {
    width: 100%;
    height: 40px;
    background-position: center;
    margin: 0;
  }

  &:hover {
    background-image: $skillpro_logo_color;
  }
}

.icon-placeholder {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 50px;
  padding: 10px;
  cursor: pointer;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: var(--light);
    outline: none !important;
    text-decoration: none !important;
    box-shadow: none !important;

    &.active i,
    &:hover i {
      background-image: linear-gradient(to bottom, #33ffff 0%, #ff00c2 100%);
      -webkit-background-image: linear-gradient(
        to bottom,
        #33ffff 0%,
        #ff00c2 100%
      );
      -moz-background-image: linear-gradient(
        to bottom,
        #33ffff 0%,
        #ff00c2 100%
      );
      background: -webkit-linear-gradient(to bottom, #33ffff 0%, #ff00c2 100%);
      background: linear-gradient(to bottom, #33ffff 0%, #ff00c2 100%);
      background: -moz-linear-gradient(to bottom, #33ffff 0%, #ff00c2 100%);
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-fill-color: rgba(0, 0, 0, 0);
      -moz-text-fill-color: transparent;
    }
  }

  i {
    font-size: 23px;
  }
}

.space-border {
  border: 1px solid var(--gray-1);
}

.single-spacer {
  width: 100%;
  height: 5px;
}

.space-divider {
  display: inline-block;
  width: 100%;
  height: 50px;
}

.space-divider-20 {
  display: inline-block;
  width: 100%;
  height: 20px;
}

.space-divider-40 {
  display: inline-block;
  width: 100%;
  height: 40px;
}

.space-divider-15 {
  display: inline-block;
  width: 100%;
  height: 15px;
}

.space-divider-10 {
  display: inline-block;
  width: 100%;
  height: 10px;
}

.space-divider-5 {
  display: inline-block;
  width: 100%;
  height: 5px;
}

.vertical-space-divider {
  display: inline-block;
  width: 2px;
  height: 40%;
  margin: 0 20px;
  background-color: var(--gray-1);
}

div.ui-marquee {
  text-overflow: ellipsis;

  &:hover {
    text-overflow: unset;
  }
}

.avatar {
  background-color: var(--gray-1);
}

.skp-rich-text-wrapper {
  color: var(--light);

  .public-DraftStyleDefault-ul {
    list-style: disc;
  }
}

sup.tooltip {
  line-height: normal;

  i {
    font-size: 14px;
  }
}

.border-box {
  border: 1px solid var(--gray-3);
  border-radius: 8px;
}

.players-box {
  cursor: pointer;

  &:hover {
    .skp-text-light ~ div {
      color: var(--primary) !important;
    }
  }
}

.profile-picture {
  .avatar {
    width: 126px;
    height: 126px;
    margin-right: 16px;

    span {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 126px;
      font-size: 63px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
}

// input[checked] ~ label i {
//   color: var(--accent);
// }

.bordered-map {
  > div {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid var(--gray-3);

    &:hover {
      background-color: var(--gray-5);
      cursor: pointer;
    }
  }
}

.bordered-list {
  border-top: 1px solid var(--gray-3);

  dd {
    position: relative;
    padding: 10px;
    border-bottom: 1px solid var(--gray-3);
    margin: 0;

    a {
      text-decoration: none;

      &:hover {
        span.text {
          color: var(--primary) !important;
        }
      }
    }

    &:hover {
      background-color: var(--gray-5);
      cursor: pointer;
    }

    &.disabled {
      position: relative;
      pointer-events: none;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--gray-4);
        z-index: 9;
        opacity: 0.5;
      }
    }

    &.row-loading {
      pointer-events: none;
      background-repeat: repeat-y;
      position: relative;
      color: #e2e2e2;
      overflow: hidden;
      animation: shine 1s infinite;
    }

    &.row-loading::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        rgba(36, 40, 53, 0) 20%,
        rgba(36, 40, 53, 0.2) 100%
      );
      animation: shimmer 1.5s infinite;
      content: "";
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.badge[data-badge]::after {
  background: var(--accent);
}

.switch_icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  background-image: $switch_icon;
  background-size: cover;
}

.skp-player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */

  &:empty {
    display: none;
  }
}

.skp-react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// add icon class to iconmoon
.la-PS5,
.la-PS4,
.la-PC,
.la-XBOX_ONE,
.la-XBOX_SERIES_X,
.la-MOBILE {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: white;
}

.la-PS5:before {
  font-family: "skillpro";
  content: "\e90c";
  font-size: 14px !important;
}

.la-PS4:before {
  font-family: "skillpro";
  content: "\e90d";
  font-size: 14px !important;
}

.la-PC:before {
  font-family: 'Line Awesome Free';
  content: "\f108";
  font-weight: 900;
}

.la-XBOX_ONE:before,
.la-XBOX_SERIES_X:before {
  font-family: "Line Awesome Brands";
  content: "\f412";
}

.la-MOBILE:before {
  font-family: 'Line Awesome Free';
  content: "\f10b";
  font-weight: 900;
}

.la-SWITCH {
  display: inline-block;
  width: 17px;
  height: 17px;
  background-image: $switch_icon;
  background-size: cover;
  margin: 4px 4px 0 4px;
}

.accordion input:checked~.accordion-header .la-angle-right, .accordion[open] .accordion-header .la-angle-right {
  transform: rotate(90deg);
}

.accordion input:checked~.accordion-body, .accordion[open] .accordion-body {
  max-height: fit-content;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.example-enter {
  opacity: 0;
  transform:translateX(-100%);
}
.example-enter-active {
  opacity: 1;
  transform:translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.example-exit {
  opacity: 1;
  transform:translateX(0);
}
.example-exit-active {
  opacity: 0;
  transform:translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

.zoom-enter {
  // opacity: 0;
}
.zoom-enter-active {
  // opacity: 1;
  animation: zoom-in-zoom-out .3s ease;
}
.zoom-exit {
  opacity: 1;
  transform:translateX(0);
}
.zoom-exit-active {
  opacity: 0;
  transform:translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}


@keyframes zoom-in-zoom-out {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
  }
}