.skp-modal-overlay {
    opacity: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, .9);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 16;
    overflow-y: scroll;
}

.skp-modal {
    transform: translateY(-200px);
    max-width: 45rem;
    margin: 0 auto;
    padding-bottom: 3.2rem;
    padding-top: 3.2rem;
    padding-right: 1.6rem;
    padding-left: 1.6rem;

    &-small {
        max-width: 23rem;
    }

    &-medium {
        max-width: 29rem;
    }

    &-large {
        max-width: 35rem;
    }

    .skp-form-control .optionListContainer {
        background-color: var(--gray-3);
    }
}

.skp-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 1.6em;
    background-color: #1b1c23;
    padding: 20px 10px;
    overflow: hidden;

    .modal-submit-internal {

        button[type="submit"] {
            min-width: 80px;
            float: right;

            // TODO: Move inside global style
            span.loading {
                display: inline-block;

                &:after {
                    border: .1rem solid white;
                    border-right-color: transparent;
                    border-top-color: transparent;
                }
            }
        }
    }
}

.skp-modal-content-no-overflow {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 1.6em;
    background-color: #1b1c23;
    padding: 20px 10px;

    .modal-submit-internal {

        button[type="submit"] {
            min-width: 80px;
            float: right;

            // TODO: Move inside global style
            span.loading {
                display: inline-block;

                &:after {
                    border: .1rem solid white;
                    border-right-color: transparent;
                    border-top-color: transparent;
                }
            }
        }
    }
}

.skp-modal-header {
    flex: 1;
    text-align: center;

    h4 {
        text-transform: capitalize;
    }
}

.skp-modal-body {
    flex: 1;
}

.skp-modal-footer {
    flex: 1;
    text-align: right;

    button {
        margin-right: 15px;
    }
}

.skp-modal-close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;

    i {
        font-size: 22px;
    }

    &:hover {
        color: var(--primary);
    }
}

.skp-modal-overlay.enter-done {
    opacity: 1;
    transition: transform .3s ease;
}

.skp-modal-overlay.enter-done .skp-modal {
    transform: translateY(0);
    transition: transform .3s ease;
}

.skp-info-modal {
    height: calc(100vh - 300px) !important;

    .padding-left {
        padding-left: 10px;
    }

    .padding-right {
        padding-right: 10px;
    }

    .datePicker {
        width: 100%;
    }

    .react-datepicker-popper {
        z-index: 3;
      }
}


