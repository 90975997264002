.action-buttons-wrapper {
    position: relative;
}

.create-dropdown-wrapper {
    position: relative;

    .skp-dropdown {
        width: 300px;
        right: 0;
        margin-top: 5px;
        padding: 15px 15px 0;

        .icon-wrapper {
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            color: var(--light);
            background-color: var(--gray-1);
            text-align: center;
            font-size: 20px;
            line-height: 50px;

            img {
                width: 20px;
            }
        }

        .container {
            padding: 0;
        }

        li {
            cursor: pointer;
            padding: 10px 5px;
            border-radius: 8px;

            &:hover {
                background-color: var(--gray-1);

                .icon-wrapper {
                    background-color: var(--gray-3);
                }
            }
        }

    }
}